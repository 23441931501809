import { createApp } from 'vue'

import App from './App.vue'
const app = createApp(App)

import router from './router'; 

// 阿里 icon
import '@/assets/icons/iconfont.css'

// 2. 引入组件样式
import 'vant/lib/index.css';

// 1. 引入你需要的组件
// 1. 引入你需要的组件
import {Checkbox, CheckboxGroup,Button,Area,Icon,Popup,Toast,Swipe ,Tabbar, TabbarItem,List,Cell,Tab,Tabs,SwipeItem,Field,CellGroup} from 'vant';

app.use(Tabbar,TabbarItem);
app.use(List);
app.use(Cell);
app.use(Tab);
app.use(Toast);
app.use(Popup);
app.use(Icon);
app.use(Area);
app.use(Button);
app.use(CheckboxGroup);
app.use(Checkbox);



app.use(Tabs);
app.use(SwipeItem);

app.use(CellGroup);
app.use(Field);
app.use(Swipe);















app.use(router)




app.mount('#app')




