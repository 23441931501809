import { createRouter, createWebHistory } from 'vue-router';




const routes = [
    {
        path: '/',
        redirect: '/404', // 默认重定向到具体路径
      },
      {
        path: '/:id',
        name: 'shopPage',
        component: () => import('@/views/IndexPage.vue'), // 动态加载
      },
      {
        path: '/common/problem',
        name: 'commonProblem',
        component: () => import('@/views/CommonProblem.vue'), // 动态加载
      },
      {
        path: '/query/order',
        name: 'queryOrder',
        component: () => import('@/views/QueryOrder.vue'), // 动态加载
      },
      {
        path: '/:userId/buy/:goodsId',
        name: 'buyGoods',
        component: () => import('@/views/BuyGoods.vue'), // 动态加载
      },
      {
        path: '/404',
        name: '404',
        component: () => import('@/views/404.vue'), // 动态加载
      },

      

      
      
]

const router = createRouter({
    history: createWebHistory(), // 使用 HTML5 模式的路由
    routes,
});

export default router;