<template>
        <router-view></router-view>
</template>




<script setup>


</script>

<style>
html{
  font-size: 10px;
}
.el-message{
  font-size: 0rem !important;
}
body{
  margin: 0;
  padding: 0;
  font-size:124px
}

</style>
